let baseUrl = process.env.VUE_APP_BASE_URL;
let admin = "/admin";
let main = "/main";
let v1 = "/v1/api";

const api = {
  // Auth
  login: baseUrl + admin + "/login",

  branch : baseUrl + v1 + admin + "/branch",
  service : baseUrl + v1 + admin + "/category",
  customer : baseUrl + v1 + admin + "/customer",
  user : baseUrl + v1 + admin + "/users",
  product : baseUrl + v1 + admin + "/products",
  order : baseUrl + v1 + admin + "/orders",
  upload : baseUrl + v1 + "/storage/image"
};

export default api;
