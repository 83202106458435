<template>
    <div class="calculadora">
        <div class="expressao">
            {{ expressao }}
        </div>
        <div class="operadores">
            <button @click="adicionarOperador(numero)" v-for="numero in OPERACOES"
                :class="numero === MAIOR_OPERADOR ? 'botao-maior' : 'botao'" type="button">
                {{ numero }}
            </button>
        </div>
        <button class="submit" @click="handleClickSomar">
            {{ $t("calculate") }}
        </button>
        <button class="submit" @click="handleClickLimpar">
            {{ $t("clear") }}
        </button>
    </div>
</template>

<script >
export default {
    name: 'home',
    data() {
        return {
            OPERACOES: [7, 8, 9, "*", 4, 5, 6, "+", 1, 2, 3, '-', 0, ".", "/" ],
            OPERADORES_MATEMATICOS: ["*", "+", "-", "/"],
            MAIOR_OPERADOR: 0,
            numero: "",
            expressao: "",
            total: 0,
            tabActive: "user", //enums: calculator, user, agent
            body: {
                odd: 0,
                amount: 0,
                rate: 0
            },
            result: 0
        }
    },
    components: {
        
    },
    mounted() {

    },
    unmounted() {

    },
    created() {

    },
    methods: {
        adicionarOperador(operador) {
            this.expressao = this.expressao + String(operador)
        },

        handleClickSomar() {
            this.expressao = eval(this.expressao)
        },

        handleClickLimpar() {
            this.expressao = ""
        }
    }
}
</script>

<style>
.calculadora {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    background: #0072cd;
    padding: 26px;
    border-radius: 6px;
}

.expressao {
    max-width: 400px;
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    font-size: 1.25rem;
    text-align: right;
    padding: 6px;
    border-radius: 4px;
    background-color: #fff;
}

.operadores {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    max-width: 400px;
    width: 100%;

}

.botao,
.botao-maior {
    display: flex;
    justify-content: center;
    padding: 6px;
    background: #c6c6c6;
    border: none;
    background: #dbecff;
    border-radius: 4px;
}

.botao-maior {
    grid-column-start: 1;
    grid-column-end: 3;
}

.submit {
    width: 100%;
    max-width: 400px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    padding: 6px;
    background: #c6c6c6;
    border: none;
    background: #dbecff;
    border-radius: 4px;
}
</style>