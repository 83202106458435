<div class="login-container">
    <div class="col-6 sm-hidden by-primary-dark sticky-top">
        <div class="absolute-center txt-center">
            <img :src="require('@/assets/logo2.png')" style="width: 200px;" />
        </div>
    </div>
    <div class="col-6 col-sm-12 box-login">
        <p class="font10 m0">Welcome back</p>
        <p class="font24 bold m0">Login to your account</p>
        <div v-if="errorMessage" class="row mv8 p12 bg-danger-light radius4">
            <div class="row txt-danger pr20">
                <i class="fa fa-exclamation-triangle mr6"></i>
                <span>{{errorMessage}}</span>
                <i @click="errorMessage = ''" class="fa fa-times absolute-top-right p2 pointer"></i>
            </div>
        </div>
        <div class="row mt16">
            <c-input label="Username" v-model:value="body.username" type="text"
                :isError="isSubmitted && !body.username" />
        </div>
        <div class="row mt16">
            <c-input label="Password" v-model:value="body.password" :type="showPassword ? 'text' : 'password'"
                :isError="isSubmitted && !body.password" class="pr32" />
            <i @click="showPassword = !showPassword"
                class="fa fa-eye absolute-top-right mt25 mr2 ph12 pv6 pointer bg-secondary"></i>
        </div>
        <div class="row mt12">
            <div class="col-6">
                <input type="radio" /> Remember me
            </div>
            <div class="col-6 txt-danger txt-right">
                Forgot password?
            </div>
        </div>
        <div class="row mv16">
            <button v-if="!isSubmitting" @click="onLogin" class="row btn btn-md radius20 txt-white"
                style="background: var(--primary-dark);">Login
                now</button>
            <button v-else class="row btn btn-md radius20 txt-white"
                style="background: var(--primary-dark);">Loading...</button>
        </div>
    </div>
</div>