import { globals } from "./../main";
const Helper = {};

Helper.getMediaType = function (url) {
  if (url) {
    const images = ["jpg", "jpeg", "gif", "png"];
    const videos = ["mp4", "mov", "3gp", "ogg"];
    const extension = url.split(".").pop();
    if (images.includes(extension)) {
      return "image";
    } else if (videos.includes(extension)) {
      return "video";
    } else {
      return "unknown";
    }
  } else {
    return "empty";
  }
};

Helper.base64toFile = function (dataurl, filename) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "image/jpeg" });
};

Helper.getImageDefault = function (path) {
  if (!path) {
    return require("@/assets/images/image-default.png");
  }
};

Helper.getFullImage = function (path) {
  if (!path) {
    return require("@/assets/images/image-default.png");
  }
  return process.env.VUE_APP_BASE_URL_IMAGE + "/" + path + "?cache=none";
};

Helper.timeToMillisecond = function (t) {
  var h = Number(t.split(":")[0]) * 60 * 60;
  var m = Number(t.split(":")[1]) * 60;
  var s = Number(t.split(":")[2]);
  return (h + m + s) * 1000;
};

Helper.formatPrice = function (price) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(price);
};

Helper.formatAmount = function (amount, fixed = 0) {
  amount = amount || 0
  if(amount == 0) fixed = 0
  return amount.toFixed(fixed)
};

Helper.formatNumber = (number) => {
  if (number == null) return 0;

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Helper.formatPhoneNumber = (data) => {
  if (!data || isNaN(data)) {
    return "Null";
  }

  const phoneNumber = data.replace("+855", "0");
  const phone = phoneNumber.split("") || data.split("");

  const countIndex = [phone.slice(0, 3).join(""), phone.slice(3, 6).join(""), phone.slice(6, 9).concat(phone.slice(9)).join("")].filter(
    (item) => item.length > 0
  );

  return countIndex.join(" ");
};

Helper.formatPhoneNumberWithPrefixCountry = (data) => {
  console.log("data ", data);
  if (!data || isNaN(data)) {
    return "Null";
  }

  const firstDigit = data.substr(0, 1);
  const prefix = data.substr(0, 4);
  if (firstDigit == 0) {
    return data.replace("0", "+855");
  } else if (prefix === "+855") {
    return data;
  } else {
    return "+855" + data;
  }
};

Helper.formatTime = function (time, format = "HH:mm:ss A") {
  return (time && globals.$moment(time, ["h:mmA"]).format(format)) || "n/a";
};

Helper.formatDate = function (date, format = "DD-MMM-YYYY HH:mm:ss") {
  return date && globals.$moment(date).format(format);
};

Helper.formatDateShort = function (date) {
  if (!date) return "";

  return globals.$moment(date).format("DD MMMM YYYY");
};

Helper.formatDateISOString = function (date) {
  return date && globals.$moment(date).toISOString();
};

Helper.generatePassword = function (length) {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

Helper.customPagination = function (data) {
  let pagination = {
    limit: data.size,
    totalPage: Math.ceil(data.totalPage),
    page: data.page,
    length: data.total
  };
  return pagination;
};

let sortByFieldName = "";
Helper.sortByFieldName = function (fieldName, list) {
  if (sortByFieldName && sortByFieldName == fieldName) {
    list = list.reverse();
    return list;
  }
  sortByFieldName = fieldName;

  // Return if product is empty
  if (list.length == 0) {
    return [];
  }

  // Filter string
  list = list.sort(function (a, b) {
    return ("" + a[fieldName]).localeCompare(b[fieldName]);
  });

  // Filter number
  list = list.sort(function (a, b) {
    return parseFloat(a[fieldName]) - parseFloat(b[fieldName]);
  });

  return list;
};

Helper.customRouteQuery = (keys) => {
  for (const [key, value] of Object.entries(keys)) {
    !String(value) && delete keys[key];
  }
  return keys;
};

Helper.setParams = (keySearch = {}, routeQuery, pagination) => {
  let query = "?";

  if (pagination) {
    query = query + "page=" + pagination?.page + "&size=" + pagination?.limit;
    pagination.limit = Number(pagination?.limit);
    pagination.page = Number(pagination?.page);
  }
  
  query = query + Helper.setRouteQuery(keySearch, routeQuery);
  console.log("setParams ", query);
  return query;
};

Helper.enum = (enumName, val) => {
  if (!globals.$enum[enumName]) {
    return "Not Found";
  }
  let result = globals.$enum[enumName].find((element) => {
    return element.val == val;
  });
  return result?.label;
};

Helper.setRouteQuery = (keySearch, routeQuery) => {
  let query = "";
  for (const [key, value] of Object.entries(routeQuery)) {
    if (key != "page" && key != "limit") {
      keySearch[key] = value || "";
      query = query + "&" + key + "=" + value;
    }
  }
  return query;
};

Helper.mapEnum = (val, arrObj) => {
  return (arrObj.find((item) => item.val === val) || {}).label || "Unknown";
};

Helper.permission = (code) => {
  if (code === "ALLOWED") {
    return true;
  }
  if (!globals.$store?.state?.permissions || !code) {
    return false;
  }
  let result = globals.$store.state.permissions.includes(code);
  return result;
};

Helper.formatOrdinals = (day) => {
  const enOrdinalRules = new Intl.PluralRules("en-US", { type: "ordinal" });

  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  const rule = enOrdinalRules.select(day);
  const suffix = suffixes.get(rule);

  return { day, suffix };
};

export default Helper;
