export default {
    name: 'c-select',
    props: {
        value: {
            validator: () => true,
        },
        optionValue: {
            validator: () => true,
        },
        optionLabel: { type: String, default: "" },
        options: { type: Array, default: () => [] },
        label: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        isError: { type: [Boolean, Number], default: false },
        readonly: { type: Boolean, default: false },
        errorMessage: { type: String, default: "" },
        searchable: { type: [Boolean, Number], default: false },
        isIcon: { type: [Boolean, Number], default: false },
    },
    emits: ["update:value", "select"],
    data() {
        return {
            isDropdown: false,
            keySearch: "",
            selectedValue: "",
            optionList: this.options
        }
    },
    components: {

    },
    created() {
        this.selectedValue = this.findOptionLabel(this.value)
    },
    watch: {
        value: function (newValue, oldValue) {
            this.selectedValue = this.findOptionLabel(newValue)
        },
        options: function (newValue, oldValue) {
            this.optionList = newValue
            this.selectedValue = this.findOptionLabel(this.value)
        }
    },
    computed: {
    },
    methods: {
        onClickItem(index) {
            let option = this.optionList[index]
            this.selectedValue = this.optionLabel ? option[this.optionLabel] : option
            this.$emit('update:value', option[this.optionValue] != null ? option[this.optionValue] : option)
            this.$emit('select', option[this.optionValue] != null ? option[this.optionValue] : option)
            this.onHideDropdown()
            this.keySearch = ""
            this.onFilter()
        },

        onFilter() {
            if (!this.options) { return }
            this.optionList = this.options.filter(item => {
                let option = item
                if (this.optionLabel) {
                    option = item[this.optionLabel]
                }
                return option.toLowerCase().includes(this.keySearch.toLowerCase())
            })
        },

        onHideDropdown() {
            this.isDropdown = false
        },

        findOptionLabel(optionValue) {
            if (!this.options) { return "" }
            let index = this.options.findIndex(item => {
                let option = item
                if (this.optionValue) {
                    option = item[this.optionValue]
                }
                return option == optionValue
            })
            return index > -1 ? this.options[index][this.optionLabel] || this.options[index] : ""
        },

        onShowDropdown() {
            if (this.readonly) { return }
            this.isDropdown = true
        }
    }
}