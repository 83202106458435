<div aria-label="Page">
    <div class="paginate">
        <div class="showing">
            <span class="central-page mr10">Show per row : </span>
            <select @change="goTo(1)" v-model="pagination.limit" class="background-none input limit-change">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
        <ul class="justify-content-center page-list">
            <button @click="goTo(Number(pagination.page)-1)" class="page-item btn btn-default mr20 prev">
                <span class="page-link">Prev</span>
            </button>
            <span class="central-page mr20">Page {{pagination.page}} of
                {{pagination.totalPage}}</span>
            <button @click="goTo(Number(pagination.page)+1)" class="page-item btn btn-default mr10 next">
                <span class="page-link">Next</span>
            </button>
            <span class="central-page mr15">Go to</span>
            <input @keyup.enter="goTo(Number(inputPage))" v-model="inputPage" type="number" min="1"
                class="h29 page-textbox" />
            <button @click="goTo(Number(inputPage))" class="page-item btn btn-primary ml10" :disabled="isLoading">
                <span class="page-link">Go</span>
            </button>
        </ul>
    </div>
</div>