import {
  createRouter,
  createWebHashHistory
} from "vue-router";

import Home from "@/views/home";
import Login from "@/views/login";
import PageNotFound from "@/views/page-not-found";

const needLogin = (to, from, next) => {
  if (!$cookies.get("token")) {
    return next({ name: "login" });
  }
  return next();
};

const noNeedLogin = (to, from, next) => {
  if ($cookies.get("token")) {
    return next({ name: "home" });
  }
  return next();
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: noNeedLogin,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: noNeedLogin,
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
