export default {
    name: 'c-loading',
    data() {

    },
    components: {

    },
    created() {

    },
    watch: {
    },
    computed: {
    },
    methods: {
    }
}