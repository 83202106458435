export default {
    name: 'pagination',
    props: {
        pagination: { type: Object, default: () => { } },
        isLoading: { type: Boolean, default: false }
    },
    data() {
        return {
            page: 1,
            limit: 10,
            inputPage: null,
        }
    },

    components: {

    },
    created() {

        console.log("pagination ", this.pagination )
    },
    watch: {
        '$route.fullPath': function () {

        }
    },
    computed: {
    },
    methods: {
        BackPage(type) {
            this.select.menu = type;
            if (this.pagination.page == 1) {
                this.pagination.page = this.pagination;
            } else {
                this.pagination.page--;
            }

            if (this.$route.query.id) {
                this.$router.push({
                    path: this.$route.path,
                    query: {
                        page: this.pagination.page,
                        limit: this.pagination.limit,
                        id: this.pagination.id
                    }
                });
                this.method(this.pagination.page, this.pagination.id);
            } else {
                this.$router.push({
                    path: this.$route.path,
                    query: {
                        page: this.pagination.page,
                        limit: this.pagination.limit
                    }
                });
                this.method(this.pagination.page);
            }
        },

        goTo(page) {
            if (page <= 0 || page > this.pagination.totalPage) { return }
            this.$root.$router.push({
                query: Object.assign({}, this.$route.query, {
                    page: page,
                    limit: this.pagination.limit
                })
            })
        }
    }
}