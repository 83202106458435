import VueCookies from "vue-cookies";
import { globals } from "./../../main";
import Helper from "./../Helper";

const MainService = {};

MainService.headers = function () {
    let token = VueCookies.get("token");
    if (token) {
        let header = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        return header;
    } //else { MainService.logout() }
};

MainService.headersFormData = function () {
    let token = VueCookies.get("token");
    if (token) {
        let header = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
            },
        };
        return header;
    }
};

MainService.headerWithoutToken = function () {
    let header = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return header;
};

MainService.validateError = function (error) {
    let httpCode = error?.response?.status;
    let data = {};

    switch (httpCode) {
        case 401:
            if (error?.response?.data?.statusCode === 4410) {
                error.response.data.isExpiredToken = true;
                console.log("error?.response?.data ", error?.response?.data)
                return error?.response?.data;
            } else {
                MainService.logout();
                break; // Add a break statement to exit the switch statement
            }
        case 429:
            globals.$store.commit("UPDATE_TOO_MANY_REQUESTED", true);
        default:
            data = {
                statusCode: error?.response?.status,
                success: false,
                error: error?.response?.data,
            };

            return data;
    }
};

MainService.validateResponse = function (response) {
    globals.$store.commit("UPDATE_TOO_MANY_REQUESTED", false);
    const data = {
        statusCode: response?.status,
        success: true,
        data: response?.data?.results || response?.data?.message,
        meta: response?.data?.page
            ? Helper.customPagination(response?.data)
            : {},
    };

    return data;
};

MainService.logout = function () {
    VueCookies.remove("token");
    VueCookies.remove("refreshToken");

    location.reload();
};

export default MainService;
