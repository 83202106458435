export default {
    name: 'c-display-image',
    props: {
        src: { type: String, default: "" }
    },
    emits: ["onClose"],
    data() {
        return {
            isImageLoaded: false
        }
    },
    components: {

    },
    created() {

    },
    watch: {
    },
    computed: {
    },
    methods: {
        onImgLoad() {
            this.isImageLoaded = true
        },
        onClose() {
            this.$emit("onClose")
        }
    }
}