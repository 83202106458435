import { globals } from './../main'
const toastTypes = ['info', 'danger', 'warning', 'success', 'default']
const Toast = function (text, type = 'default', timeout = 3000) {
    globals.$vueToast(text, {
        type: toastTypes.includes(type) ? type : 'default' || 'default',
        showIcon: true,
        timeout: timeout
    })
}

export default Toast