<div v-click-outside="onHideDropdown" @click="onShowDropdown" class="box-input">
    <label v-if="label" :for="label" class="txt-ellipsis">{{label}}</label>
    <div :id="label" :class="{'outline-danger': isError}" class="row border radius4 pr32"
        style="min-height: 34px;">
        <img v-if="isIcon" :src="require('@/assets/images/image-default.png')"
            class="w24 h24 absolute-top-left m4 radius4" />
        <div :class="{pl36 : isIcon}" class="value-lable p6 txt-ellipsis">{{selectedValue}}</div>
        <i class="fa fa-angle-down bold ic-arrow-down"></i>
    </div>
    <p v-if="isError" class="mb0 txt-danger font12 mt3">
        <span v-if="errorMessage">{{errorMessage}}</span>
        <span v-else>{{label}} is required.</span>
    </p>
    <div v-if="isDropdown" class="box-dropdown">
        <input v-if="searchable" @input="onFilter" v-model="keySearch" type="text" class="input mb10"
            placeholder="Search" />
        <div class="row dropdown-container">
            <div @click="onClickItem(optionIndex)" v-for="(option, optionIndex) in optionList"
                class="row p6 pointer hover-list" style="line-height: normal;font-weight: 400;">
                {{optionLabel ? option[optionLabel] : option}}
            </div>
        </div>
        <div v-if="!optionList || !optionList[0]" class="row txt-center pv12">
            No Data
        </div>
    </div>
</div>