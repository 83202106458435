export default {
    name: 'table-loading',
    props: {

    },
    data() {
        return {
        }
    },

    components: {

    },
    create() {
    },

    methods: {

    }
}