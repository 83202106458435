export default {
    name: 'page-not-found',
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    unmounted() {

    },
    created() {

    },
    methods: {

    }
}