export default {
    name: 'modal-confirm',
    props: {
        message: { type: String, default: "" },
        isLoading: { type: Boolean, default: false },
    },
    emits: ["onClose", "onConfirm"],
    data() {
        return {
        }
    },

    components: {

    },
    create() {
    },

    methods: {
        onClose() {
            this.$emit('onClose')
        },

        onConfirm() {
            this.$emit('onConfirm')
        }
    }
}