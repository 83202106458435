export default {
    name: 'c-input',
    props: {
        value: {
            type: [String, Number],
            validator: () => true,
        },
        label: { type: String, default: "" },
        type: { type: String, default: "" },
        maxLength: { type: Number, default: 0 },
        placeholder: { type: String, default: "" },
        isError: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        errorMessage: { type: String, default: "" },
    },
    emits: ['update:value'],
    data() {
        return {
            showPassword: false
        }
    },

    components: {

    },
    created() {
    },
    watch: {
        '$route.fullPath': function () {

        }
    },
    computed: {
    },
    methods: {
        isNumber(evt) {
            if (this.maxLength) {
                if (this.value.length >= this.maxLength) {
                    evt.preventDefault();
                }
            }

            if (this.type == "letter") {
                if (!/[a-z0-9]/.test(evt.key)) {
                    this.ignoredValue = evt.key ? evt.key : "";
                    evt.preventDefault();
                }
            }

            if (this.type == "number") {
                evt = evt ? evt : window.event;
                let charCode = evt.which ? evt.which : evt.keyCode;
                if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
                ) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }

            if (this.type == "tel") {
                evt = evt ? evt : window.event;
                let charCode = evt.which ? evt.which : evt.keyCode;
                if (evt.target.value.length > 9) {
                    evt.preventDefault();
                }
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },
        onEnter() {
            this.$emit('enter')
        }
    }
}