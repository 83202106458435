import { createStore } from 'vuex';

export default createStore({
    state: {
        tooManyRequested: false,
        user: "",
        permissions: [],
        menus: [
            {
                src: require("@/assets/icons/dashboard.svg"),
                label: "Dashboard",
                routeName: "dashboard",
                val: "dashboard",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "Branch",
                routeName: "list-branch",
                val: "branch",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "Service",
                routeName: "list-service",
                val: "service",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "Product",
                routeName: "list-product",
                val: "product",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "Customer",
                routeName: "list-customer",
                val: "customer",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "User",
                routeName: "list-user",
                val: "user",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            {
                src: require("@/assets/icons/pendingAuthorize.svg"),
                label: "Order",
                routeName: "list-order",
                val: "order",
                isDropped: false,
                permissionCode: "ALLOWED",
                childs: []
            },
            // {
            //     src: require("@/assets/icons/pendingAuthorize.svg"),
            //     label: "Agent",
            //     routeName: "list-agent",
            //     val: "agent",
            //     isDropped: false,
            //     permissionCode: "ALLOWED",
            //     childs: []
            // },
            // {
            //     src: require("@/assets/icons/pendingAuthorize.svg"),
            //     label: "Member",
            //     routeName: "list-member",
            //     val: "member",
            //     isDropped: false,
            //     permissionCode: "ALLOWED",
            //     childs: []
            // },
            // {
            //     src: require("@/assets/icons/setting.svg"),
            //     label: "Setting",
            //     routeName: "setting",
            //     val: "setting",
            //     isDropped: false,
            //     childs: [
            //         {
            //             label: "Amount Pool",
            //             routeName: "list-amountPool",
            //             val: "amountPool",
            //             permissionCode: "VIEW_POOL_AMOUNT",
            //             childs: []
            //         },
            //         {
            //             label: "Bid Duration",
            //             routeName: "list-bidDuration",
            //             val: "bidDuration",
            //             permissionCode: "VIEW_BID_DURATION",
            //             childs: []
            //         },
            //     ]
            // },
        ],
        mediaDirectory: [],
        clients: {},
        menubar: {},
    },
    getters: {},
    mutations: {
        UPDATE_TOO_MANY_REQUESTED: (state, payload) => {
            console.log("UPDATE_TOO_MANY_REQUESTED", payload)
            state.tooManyRequested = payload;
        },
        STORE_PROFILE: (state, item) => {
            state.user = item.user || ""
            state.permissions = item.permissions || []
        },
        UPDATE_MENUBAR: (state, item) => {
            state.menubar = item || {};
        },
        UPDATE_CLIENT: (state, item) => {
            state.clients = item || {};
        },
        STORE_MEDIA_DIRECTORY: (state, item) => {
            state.mediaDirectory = item;
        },
    },
    actions: {
        updateMenubar({ commit }, item) {
            commit("UPDATE_MENUBAR", item);
        },
    },
});