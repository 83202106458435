import axios from "axios";
import VueCookies from "vue-cookies";
import MainService from "./MainService";
import ApiConstant from "../constants/ApiConstants";

const AuthService = {};

AuthService.login = async function (body) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'x-udid': body.uuid
        }
    }
    return await axios
        .post(ApiConstant.login, body, header)
        .then((response) => {
            return MainService.validateResponse(response);
        })
        .catch((error) => {
            return MainService.validateError(error);
        });
};

AuthService.refreshToken = async function () {
    let body = {
        refreshToken: VueCookies.get("refreshToken"),
    };
    return await axios
        .post(ApiConstant.refreshToken, body, MainService.headerWithoutToken())
        .then((response) => {
            VueCookies.set("token", response?.data?.data?.token);
            VueCookies.set("refreshToken", response?.data?.data?.refreshToken);
            return "OK";
        })
        .catch(function () {
            MainService.logout();
        });
};

AuthService.logout = async function () {
    return await axios
        .post(ApiConstant.logout, {}, MainService.headers)
        .then((response) => {
            return MainService.validateResponse(response);
        })
        .catch((error) => {
            return MainService.validateError(error);
        });
};

AuthService.profile = async function () {
    return await axios
        .get(ApiConstant.profile, MainService.headers())
        .then((response) => {
            return MainService.validateResponse(response);
        })
        .catch((error) => {
            return MainService.validateError(error);
        });
};

AuthService.changePassword = async function (body) {
    return await axios
        .post(ApiConstant.changePassword, body, MainService.headers())
        .then((response) => {
            return MainService.validateResponse(response);
        })
        .catch((error) => {
            return MainService.validateError(error);
        });
};

export default AuthService;
