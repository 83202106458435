import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios from "axios";
import moment from "moment";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { createApp } from "vue";
import VueCookies from "vue-cookies";
import { Vue3ToggleButton } from "vue3-toggle-button";
import "../node_modules/vue3-toggle-button/dist/style.css";
import App from "./App.vue";
import Toast from "./plugin/toast";
import router from "./router";
import store from "./store";
import Helper from "./utils/Helper";
import Enum from "./utils/constants/EnumConstants";

import { createI18n } from "vue-i18n";
import cn from "./lang/cn";
import en from "./lang/en";

import CDisplayImage from "@/components/shared/c-display-image";
import CInput from "@/components/shared/c-input";
import CLoading from "@/components/shared/c-loading";
import CNoData from "@/components/shared/c-no-data";
import CSelect from "@/components/shared/c-select";
import CToggle from "@/components/shared/c-toggle";
import ModalConfirm from "@/components/shared/modal-confirm";
import Pagination from "@/components/shared/pagination";
import TableLoading from "@/components/shared/table-loading";

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$helper = Helper;
app.config.globalProperties.$enum = Enum;
app.config.globalProperties.$vueToast = createToast;
app.config.globalProperties.$toast = Toast;
app.config.globalProperties.$store = store;
// app.config.globalProperties.$cookies = VueCookies

app.directive("click-outside", {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const defaultLang = VueCookies.get('lang')

const i18n = new createI18n({
  locale: defaultLang || "cn",
  messages: {
    en: en,
    cn: cn,
  },
});

app.component("QuillEditor", QuillEditor);
app.component("Vue3ToggleButton", Vue3ToggleButton);
app.component("c-modal-comfirm", ModalConfirm);
app.component("c-table-loading", TableLoading);
app.component("c-pagination", Pagination);
app.component("c-no-data", CNoData);
app.component("c-loading", CLoading);
app.component("c-input", CInput);
app.component("c-select", CSelect);
app.component("c-toggle", CToggle);
app.component("c-display-image", CDisplayImage);

app.use(VueCookies);
app.use(router);
app.use(store);
app.use(i18n);
app.mount("#app");

// export global function
const globals = app.config.globalProperties;
export { globals };
