<template>
  <div class="app-container">
    <div class="row">
      <div class="header-container">
        <div
          class="row"
          style="max-width: 1000px; margin: 0 auto; padding-top: 16px"
        >
          <img
            :src="require('@/assets/images/header-img.png')"
            class="img-header"
          />
          <div class="row">
            <img
              src="https://d3nf0jd8mxe8xr.cloudfront.net/pepsi-logo.svg"
              style="height: 32px; float: left"
            />
            <div class="go-right">
              <button
                @click="switchLang('en')"
                :class="$i18n.locale == 'en' ? 'btn-lang-active' : 'btn-lang'"
              >
                English
              </button>
              <button
                @click="switchLang('cn')"
                :class="$i18n.locale == 'cn' ? 'btn-lang-active' : 'btn-lang'"
              >
                Chinese
              </button>
            </div>
          </div>
          <div class="row">
            <p class="app-title">MARKSIX CALCULATOR</p>

            <p class="app-desc">
              {{ $t("appDescription") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="row mt24 bg-white">
      <div class="row footer-container">
        <p class="more-feature">{{ $t("moreFeatures") }}</p>
        <div class="row">
          <a href="https://178dh.cc/" target="_blank" class="feature-item"
            >🌟六合彩视频直播🌟</a
          >
          <a href="https://ok11.cc/" target="_blank" class="feature-item"
            >新澳门视频直播</a
          >
          <a
            href="https://bet.hkjc.com/marksix/index.aspx?lang=ch"
            target="_blank"
            class="feature-item"
            >香港马会</a
          >
          <a href="http://bf.90vs1.com/" target="_blank" class="feature-item"
            >足球比分网</a
          >
          <a href="https://a8a8.cc/xglhc" target="_blank" class="feature-item"
            >六合彩库</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  components: {},
  created() {},
  computed: {},
  methods: {
    switchLang(lang) {
      this.$cookies.set("lang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style>
.pipe {
    color: #d21818;
    padding: 0 6px;
    font-weight: bold
    /* Change this to whatever color you want */
}
.header-container {
  width: 100%;
  height: 350px;
  background: #dbecff;
  position: relative;
}
.app-title {
  font-size: 32px;
  margin: 32px 0 0 0;
}
.app-desc {
  font-size: 20px;
  color: #999;
  margin: 0;
}
.alert-box {
  width: 320px;
  background: var(--danger-light);
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 9999;
  padding: 12px;
}

.ql-editor {
  min-height: 100px;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.app-container {
  background: var(--light);
}

.wrapper-container {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  margin-top: 0;
  overflow: auto;
  border-radius: 0;
  background: var(--white);
  overflow-y: auto;
}

.wrapper-container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.wrapper-container::-webkit-scrollbar-track {
  background: var(--light);
}

.wrapper-container::-webkit-scrollbar-thumb {
  background: var(--secondary-light);
}

.wrapper-container::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}
.more-feature {
  position: relative;
  padding-bottom: 3px;
  margin: 0;
}
.more-feature::before {
  content: "";
  position: absolute;
  width: 36px;
  height: 3px;
  background: #0172cd;
  border-radius: 2px;
  bottom: 0;
  left: 0;
}
.feature-item {
  padding: 16px 10px;
  cursor: pointer;
  display: inline-block;
  color: #0072cd;
}
.feature-item:hover {
  font-weight: bold;
}

.btn-lang {
  border: none;
  outline: none;
  padding: 3px 6px;
  border-right: 1px solid #dbecff;
  cursor: pointer;
}

.btn-lang-active {
  border: none;
  outline: none;
  padding: 3px 6px;
  border-right: 1px solid #dbecff;
  cursor: pointer;
  background: #0172cd;
  color: white;
}

.img-header {
  height: 160px;
  position: absolute;
  top: 62px;
  right: 0;
}

.footer-container {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .wrapper-container {
    width: calc(100vw - 24px);
    margin-left: 12px;
  }
  .header-container {
    padding: 0 16px;
  }
  .footer-container{
    padding: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .img-header {
    display: none;
  }
  .app-title {
    font-size: 26px;
    text-align: center;
  }
  .app-desc {
    font-size: 16px;
    text-align: center;
  }
  .header-container {
    height: 292px;
  }
}

@media screen and (max-width: 666px) {
  .app-title {
    font-size: 20px;
  }
  .app-desc {
    font-size: 12px;
  }
  .header-container {
    height: 262px;
  }
}
</style>
