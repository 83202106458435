import AuthService from "@/utils/services/AuthService"

export default {
    name: 'login',
    data() {
        return {
            isSubmitted: false,
            isSubmitting: false,
            errorMessage: "",
            showPassword: false,
            body: {
                username: "admin",
                password: "123456"
            }
        }
    },
    components: {

    },
    mounted() {

    },
    unmounted() {

    },
    created() {
        // this.checkToken()
    },
    methods: {
        checkToken(){
            let token =  this.$cookies.get('token')
            alert(token)
            if(token)
                this.$router.push({'name': "dashboard"})
        },
        onLogin() {
            this.isSubmitted = true
            let validationMsg = this.validateLoginBody()
            if (validationMsg != "OK") { return }
            this.isSubmitting = true
            let body = {
                username: this.body.username,
                password: this.body.password
            }
            AuthService.login(body).then(response => {
                this.isSubmitting = false
                if (!response.success) {
                    this.errorMessage = response.error.message
                    return
                }
                this.$cookies.set('token', response.data.token)
                this.$cookies.set('refreshToken', response.data.refreshToken)
                location.reload()
            })
        },

        validateLoginBody() {
            let body = this.body
            if (!body.username) { return "Username is required." }
            if (!body.password) { return "Password is required." }
            return "OK"
        }
    }
}