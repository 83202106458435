const enums = {
  gender: [
    { val: 1, key: "DEFAULT", label: "Default" },
    { val: 2, key: "MALE", label: "Male" },
    { val: 3, key: "FEMALE", label: "Female" },
    { val: 4, key: "OTHER", label: "Other" },
  ],
  userStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  deviceType: [
    { val: 0, key: "DEFAULT", label: "Default" },
    { val: 1, key: "IOS", label: "iOS" },
    { val: 2, key: "ANDROID", label: "Android" },
    { val: 3, key: "WEB", label: "Web" },
    { val: 4, key: "API", label: "API" },
  ],
  userLoginType: [
    { val: 1, key: "ADMIN_USER", label: "Admin User" },
    { val: 2, key: "CUSTOMER", label: "Customer" },
  ],
  roleStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  permissionStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  customerStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "ACTIVE", label: "Active" },
    { val: 3, key: "INACTIVE", label: "Inactive" },
    { val: 4, key: "DEPUTE", label: "Depute" },
    { val: 5, key: "BLACKLISTED", label: "Blacklisted" },
  ],
  customerLevelStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  customerLevelTypeStatus: [
    { val: 1, key: "POOL_AMOUNT", label: "Pool Amount" },
    { val: 2, key: "POOL_USER", label: "Pool User" },
    { val: 3, key: "POOL_ACTIVE", label: "Pool Active" },
  ],
  guarantorStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "APPROVED", label: "Approved" },
    { val: 3, key: "REJECTED", label: "Rejected" },
    { val: 4, key: "REMOVED", label: "Removed" },
  ],
  guarantorAction: [
    { val: 1, key: "APPROVED", label: "Approved" },
    { val: 2, key: "REJECTED", label: "Rejected" },
    { val: 3, key: "REMOVED", label: "Removed" },
  ],
  poolRequestStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "APPROVED", label: "Approved" },
    { val: 3, key: "REJECTED", label: "Rejected" },
  ],
  poolStatus: [
    { val: 1, key: "AVAILABLE", label: "Available" },
    { val: 2, key: "ACTIVE", label: "Active" },
    { val: 3, key: "CLOSED", label: "Closed" },
  ],
  customerPoolStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
    { val: 3, key: "LEFT", label: "Left" },
  ],
  customerDetailPoolStatus: [
    { val: 1, key: "ACTIVE", label: "Ongoing" },
    { val: 2, key: "INACTIVE", label: "Won" },
    { val: 3, key: "LEFT", label: "Left" },
  ],
  customerPoolActionStatus: [
    { val: 1, key: "JOINED", label: "Joined" },
    { val: 2, key: "WON", label: "Won" },
    { val: 3, key: "LEFT", label: "Left" },
  ],
  bidStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "ACTIVE", label: "Active" },
    { val: 3, key: "END", label: "End" },
  ],
  customerBidStatus: [
    { val: 1, key: "BID", label: "Bid" },
    { val: 2, key: "WON", label: "Won" },
    { val: 3, key: "LOST", label: "Lost" },
  ],
  paymentStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "IN_REVIEW", label: "In Review" },
    { val: 3, key: "COMPLETED", label: "Completed" },
  ],
  paymentDuration: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  settlementStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "COMPLETED", label: "Completed" },
  ],
  commentType: [
    { val: 1, key: "CUSTOMER", label: "Customer" },
    { val: 2, key: "ADMIN", label: "Admin" },
  ],
  masterDataGroup: [
    { val: 1, key: "NUMBER", label: "Number" },
    { val: 2, key: "TEXT", label: "Text" },
  ],
  masterDataType: [
    { val: 1, key: "POOL_USER", label: "Pool User" },
    { val: 2, key: "PAYMENT_DURATION", label: "Payment Duration" },
    { val: 3, key: "START_POOL", label: "Start Pool" },
    { val: 4, key: "ACTIVATION_FEE", label: "Activation Fee" },
  ],
  bidFrequency: [
    { val: 1, key: "DAY", label: "Day" },
    { val: 2, key: "WEEK", label: "Week" },
    { val: 3, key: "MONTH", label: "Month" },
  ],
  transactionFeeFrom: [
    { val: 1, key: "WINNER", label: "Winner" },
    { val: 2, key: "OTHERS", label: "Others" },
  ],
  transactionFeeType: [
    { val: 1, key: "PERCENTAGE", label: "Percentage" },
    { val: 2, key: "FLAT", label: "Flat" },
  ],
  penaltyFeeFrom: [
    { val: 1, key: "WINNER", label: "Winner" },
    { val: 2, key: "OTHERS", label: "Others" },
  ],
  penaltyFeeType: [
    { val: 1, key: "PERCENTAGE", label: "Percentage" },
    { val: 2, key: "FLAT", label: "Flat" },
  ],
  penaltyPeriod: [
    { val: 1, key: "HOUR", label: "Hour" },
    { val: 2, key: "DAY", label: "Day" },
  ],
  gracePeriodType: [
    { val: 1, key: "HOUR", label: "Hour" },
    { val: 2, key: "DAY", label: "Day" },
  ],
  bankStatus: [
    { val: 1, key: "ACTIVE", label: "Active" },
    { val: 2, key: "INACTIVE", label: "Inactive" },
  ],
  currency: [
    { val: 1, key: "USD", label: "USD" },
    { val: 2, key: "KHR", label: "KHR" },
  ],
  languageApp: [
    { val: 1, key: "CUSTOMER_APP", label: "Customer App" },
    { val: 2, key: "WEB_ADMIN", label: "Web Admin" },
    { val: 3, key: "GLOBAL", label: "Global" },
  ],
  termConditionStatus: [
    { val: 1, key: "PENDING", label: "Pending" },
    { val: 2, key: "ACTIVE", label: "Active" },
    { val: 3, key: "INACTIVE", label: "Inactive" },
  ],
};

export default enums;
