<div class="modal-container">
    <div @click="onClose" class="overlay"></div>
    <div class="modal-xs">
        <div class="modal-header border-bottom">
            <b>Confirmation</b>
            <i @click="onClose" class="fa fa-times go-right p3 pointer"></i>
        </div>
        <div class="modal-body p12">
            <div v-if="message" class="text-default">{{message}}</div>
            <div v-else class="text-default">Are you sure want to do this action?</div>
        </div>
        <div class="modal-footer txt-right border-top p12">
            <button v-if="!isLoading" @click="onConfirm" class="btn btn-danger radius20 mr6">Confirm</button>
            <button v-else class="btn btn-danger radius20 mr6">Loading...</button>
            <button @click="onClose" class="btn btn-secondary radius20">Cancel</button>
        </div>
    </div>
</div>